<template>
  <div class="contact">
    <section>
      <div class="wrap">
        <div
          class="banner"
          style="background-image: url('/assets/home/2-less-cost.png')"
        ></div>

        <div class="content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="190.905"
            height="34.485"
            viewBox="0 0 190.905 34.485"
          >
            <path
              d="M-79.062-23.65v-5.94c0-3.135-1.6-4.84-5.17-4.84h-5.83c-3.52,0-5.115,1.7-5.115,4.84V-4.84c0,3.19,1.6,4.9,5.115,4.9h5.83c3.575,0,5.17-1.7,5.17-4.9v-5.83h-5.06v5.225c0,1.32-.275,1.6-1.6,1.6h-2.805c-1.32,0-1.65-.275-1.65-1.6V-28.93c0-1.32.33-1.595,1.65-1.595h2.805c1.32,0,1.6.275,1.6,1.595v5.28Zm16.61,18.04c0,1.32-.33,1.595-1.65,1.595h-3.19c-1.32,0-1.6-.275-1.6-1.595V-28.82c0-1.32.275-1.595,1.6-1.595h3.19c1.32,0,1.65.275,1.65,1.595Zm-6.27-28.82c-3.575,0-5.17,1.7-5.17,4.84V-4.84c0,3.135,1.6,4.84,5.17,4.84h6.105C-59.042,0-57.5-1.7-57.5-4.84V-29.59c0-3.135-1.54-4.84-5.115-4.84Zm29.535,0v24.145l-7.04-24.145h-5.225V0h4.565V-24.86L-39.627,0h5V-34.43Zm24.75,0h-16.5v4.015h5.775V0h4.95V-30.415h5.775Zm8.3,22.44,2.86-18.15L-.137-11.99ZM1.9,0h5L.358-34.43h-7.2L-12.952,0H-8l1.265-7.975H.523ZM26.813-23.65v-5.94c0-3.135-1.6-4.84-5.17-4.84h-5.83c-3.52,0-5.115,1.7-5.115,4.84V-4.84c0,3.19,1.6,4.9,5.115,4.9h5.83c3.575,0,5.17-1.7,5.17-4.9v-5.83h-5.06v5.225c0,1.32-.275,1.6-1.595,1.6H17.353c-1.32,0-1.65-.275-1.65-1.6V-28.93c0-1.32.33-1.595,1.65-1.595h2.805c1.32,0,1.595.275,1.595,1.595v5.28Zm19.36-10.78h-16.5v4.015h5.775V0H40.4V-30.415h5.775Zm23.485,0V-5.61c0,1.32-.275,1.595-1.65,1.595H64.873c-1.32,0-1.65-.275-1.65-1.595V-34.43H58.218V-4.84c0,3.135,1.6,4.84,5.17,4.84h6.1c3.575,0,5.17-1.7,5.17-4.84V-34.43ZM90.668-5.61c0,1.32-.275,1.595-1.6,1.595h-2.31c-1.32,0-1.65-.275-1.65-1.595V-9.9H80.107v5.06C80.107-1.7,81.7,0,85.223,0h5.335c3.575,0,5.17-1.7,5.17-4.84v-9.9c0-3.135-1.54-4.84-5.06-4.84H86.653c-1.32,0-1.65-.275-1.65-1.595V-28.82c0-1.32.33-1.595,1.65-1.595h2.31c1.32,0,1.65.275,1.65,1.595v4.29h5v-5.06c0-3.135-1.54-4.84-5.115-4.84h-5.39c-3.52,0-5.115,1.7-5.115,4.84v9.075C80-17.38,81.482-15.62,85-15.62h4.07c1.32,0,1.6.275,1.6,1.6Z"
              transform="translate(95.177 34.43)"
              fill="#078696"
            />
          </svg>

          <p>Talk to us and get more insight about your land.</p>

          <div class="details">
            <a href="tel:+441416350250">T&nbsp;(0) 141 635 0250</a>

            <a href="mailto:info@eolasinsight.com"
              >E&nbsp;info@eolasinsight.com</a
            >
          </div>

          <p>Or contact us on social media</p>

          <div class="social">
            <a
              href="https://www.linkedin.com/company/eolas-insight-ltd/"
              target="_blank"
              title="LinkedIn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 14.896 15.218"
                class="headings1"
              >
                <g fill="#078696" transform="translate(-1222.359 -29.036)">
                  <path d="M1222.359 34.036h3v10h-3z" />
                  <circle
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(1222.359 29.036)"
                  />
                  <path
                    d="M1237.254 38.65c0-2.754-.594-4.6-3.8-4.6a3.2 3.2 0 00-3 1.377h-.044V34.05h-2.85v10.2h2.974v-5.053c0-1.334.252-2.625 1.9-2.625 1.626 0 1.757 1.525 1.757 2.711v4.967h3.061v-5.6z"
                  />
                </g>
              </svg>
            </a>
            <!--            <a href="#">-->
            <!--              <svg-->
            <!--                xmlns="http://www.w3.org/2000/svg"-->
            <!--                viewBox="0 0 16.374 12.595"-->
            <!--              >-->
            <!--                <path-->
            <!--                  fill="#078696"-->
            <!--                  fill-rule="evenodd"-->
            <!--                  d="M15.922.232A6.958 6.958 0 0113.788 1a3.442 3.442 0 00-2.452-1 3.273 3.273 0 00-3.359 3.18 3.078 3.078 0 00.086.724A9.727 9.727 0 011.139.582a3.038 3.038 0 00-.455 1.6A3.139 3.139 0 002.18 4.827a3.5 3.5 0 01-1.522-.4v.04a3.233 3.233 0 002.7 3.118 3.559 3.559 0 01-.886.111 3.537 3.537 0 01-.632-.057A3.347 3.347 0 004.974 9.85 6.986 6.986 0 01.8 11.211a7.209 7.209 0 01-.8-.045A9.908 9.908 0 005.15 12.6a9.231 9.231 0 009.558-9.047c0-.138 0-.275-.009-.411a6.631 6.631 0 001.676-1.646 6.993 6.993 0 01-1.929.5A3.227 3.227 0 0015.922.232z"-->
            <!--                />-->
            <!--              </svg>-->
            <!--            </a>-->
          </div>
        </div>
      </div>
      <site-footer></site-footer>
    </section>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import SiteFooter from '@/components/SiteFooter'

export default defineComponent({
  name: 'Contact',
  components: { SiteFooter },
})
</script>
<style lang="scss" scoped>
section {
  background-color: white;

  .wrap {
    .content {
      @apply py-40;
      h1 {
        @apply text-2xl text-primary font-bold mb-8;
      }
      p {
        @apply text-black;
      }

      .details {
        @apply flex flex-col mb-8 w-full px-4;

        a {
          @apply block w-full h-12 my-2 flex items-center justify-center text-primary text-2xl font-bold;
          background-color: #f7f7f7;
        }

        @screen md {
          @apply flex-row w-auto;
          a {
            @apply w-72 h-72 my-0 mx-2;
            background-color: #f7f7f7;
          }
        }
      }

      .social {
        @apply flex items-baseline justify-center;
        a {
          svg {
            @apply w-8 mx-4 my-0;
          }
        }
      }
    }

    .headings1 {
      height:35px
    }
  }
}
</style>
